@tailwind base;
@tailwind components;
@tailwind utilities;

.busyLoader{
  background-color: #00000061;
  position: absolute;
  margin: auto;
  z-index: 999;
  padding-top: 50vh;
  padding-left: 50vw;
}

.hour_glass{
  background: url(/public/images/hour_glass.gif) no-repeat;
  padding: 14px;
  background-size: 30px 30px;
  margin-bottom: -8px;
  display: inline-block;
}

.webkit-center{
  text-align: -webkit-center;
}
.webkit-auto{
  text-align: -webkit-auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


input[type="range"] {
  border-radius: 6px;
  background-color: rgb(219, 212, 212);
  outline: none;
  transition: background 450ms ease-in;
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  background: skyblue !important;
  border-radius: 50%;
  border: 2 !important;
  border-color: skyblue !important;
  cursor:grabbing;
}
input[type=range]::-webkit-slider-thumb:hover {
  box-shadow: 0px 0px 5px 2px rgb(169, 95, 237);
  cursor:grabbing;
}
input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  color: #13bba4;
}
input[type="range"]::-moz-range-progress {
  background-color: #42787e; 
}
input[type="range"]::-moz-range-track {  
  background-color: #9a905d;
}
input[type="range"]::-ms-fill-lower {
  background-color: #43e5f7; 
}
input[type="range"]::-ms-fill-upper {  
  background-color: #9a905d;
}
input[type=range]::-moz-range-thumb {
  height: 18px;
  width: 18px;
  border-radius: 3px;
  background: skyblue !important;
  border: 1 !important;
  border-color: skyblue !important;
  border-radius: 5px;
  cursor: pointer;
}
input[type=range]::-ms-thumb {
  height: 18px;
  width: 18px;
  border-radius: 3px;
  background: skyblue;
  border-radius: 5px;
  border: 1 !important;
  border-color: skyblue !important;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset -3px 0 5px rgb(233, 229, 229); 
  border-radius: 0 3px 3px 0;
}

::-webkit-scrollbar-thumb {
  background: rgba(168, 168, 182, 0.337) !important;  
  border-radius: 0 3px 3px 0;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(122, 122, 201) !important; 
}

.shadow_animation{
  animation-name:zoom_in_out;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  box-shadow: 0px 0px 4px 0px blueviolet;
}

.red_animation{
  animation-name:zoom_in_out_red; 
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  box-shadow: 0px 0px 4px 0px rgb(99, 10, 10);
}

@keyframes zoom_in_out {
  50%   {
    box-shadow: 0px 0px 15px 5px rgb(65, 35, 164);
  }
}

@keyframes zoom_in_out_red {
  50%   {
    box-shadow: 0px 0px 15px 5px red;
  }
}

[type=checkbox]:focus, [type=radio]:focus {
  --tw-ring-offset-color: #fff0 !important;
  --tw-ring-offset-width: 0px !important;
  --tw-ring-color: #ffffff00 !important;
}

[type='radio']:checked {
  animation-name:radio_in_out;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27white%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3ccircle cx=%278%27 cy=%278%27 r=%272%27/%3e%3c/svg%3e");
}

@keyframes radio_in_out {
  from  {
    background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27white%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3ccircle cx=%278%27 cy=%278%27 r=%278%27/%3e%3c/svg%3e");
  }
  to{
    background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27white%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3ccircle cx=%278%27 cy=%278%27 r=%272%27/%3e%3c/svg%3e");
  }
}
.pdfView{
  width: 97%;
  height: 50vh;
  margin-top: 10px;
}

@media (min-width: 300px) {
  .doublePdf{
    display : block;
  }
  .doublePdf .pdfView{
    width: 100% !important;
    height: 400px !important;
    margin: 5px !important;
  }
}

@media (min-width: 1000px) {
  .doublePdf{
    display : flex;
  }
  .doublePdf .pdfView{
    width: 30vw !important;
    height: 50vh !important;
    margin: 10px !important;
  }
}
.bluebg{
  background-color: #0000ff !important;
}
.btnbluebg{
  background-color: #007bff !important;
}

.MuiSlider-root .MuiSlider-rail{
  background-color: rgb(202, 191, 191);
}
.MuiSlider-root .MuiSlider-track{
  background-color: rgb(199, 199, 240);
}
.MuiSlider-root .MuiSlider-thumb{
  background-color: white;
  border-width: 2px !important; 
  border-color: rgb(199, 199, 240);
}
.hcenter{
  text-align-last: center !important;
}
.hleft{
  text-align-last: left !important;
}

@keyframes countdown {
  0% {
      stroke-dashoffset: 0;
  }
  100% {
      stroke-dashoffset: 440;
  }
}
.timer-circle {
  animation: countdown linear forwards;
}